var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageLayout',{attrs:{"title":_vm.$t('common.overview'),"loading":!(_vm.consumptionDataLoaded && _vm.productionDataLoaded)},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.consumptionDataLoaded && _vm.productionDataLoaded)?_c('div',{staticClass:"row"},[(
          [_vm.OrganizationTypes.RETAILER, _vm.OrganizationTypes.CONSUMER].includes(
            _vm.roles.organizationType
          )
        )?_c('div',{class:_vm.roles.organizationType === _vm.OrganizationTypes.RETAILER
            ? 'col-3'
            : 'col-6'},[_c('ConsumptionSitesCard')],1):_vm._e(),(
          [_vm.OrganizationTypes.RETAILER, _vm.OrganizationTypes.PRODUCER].includes(
            _vm.roles.organizationType
          )
        )?_c('div',{class:_vm.roles.organizationType === _vm.OrganizationTypes.RETAILER
            ? 'col-3'
            : 'col-6'},[_c('ProductionSitesCard')],1):_vm._e(),_c('div',{class:[_vm.OrganizationTypes.PRODUCER].includes(_vm.roles.organizationType)
            ? 'col-4'
            : 'col-6'},[_c('Card',{attrs:{"title":_vm.$t('labels.energy_data'),"title-type":"label"}},[_c('div',{staticClass:"d-flex"},[(
                [
                  _vm.OrganizationTypes.RETAILER,
                  _vm.OrganizationTypes.GRIDOPERATOR,
                  _vm.OrganizationTypes.CONSUMER ].includes(_vm.roles.organizationType)
              )?_c('div',{staticClass:"flex-1 pr-2"},[_c('h4',{staticClass:"card__subtitle"},[_vm._v(" Σ "+_vm._s(_vm.$t("labels.sum_consumed"))+" ")]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"card__numeral"},[_vm._v(" "+_vm._s(_vm.seperateThousand( _vm.totalAmountData.total_amount_consumed / 1000000 ))+" ")]),_vm._v(" MWh ")])]):_vm._e(),(
                [
                  _vm.OrganizationTypes.RETAILER,
                  _vm.OrganizationTypes.GRIDOPERATOR,
                  _vm.OrganizationTypes.PRODUCER ].includes(_vm.roles.organizationType)
              )?_c('div',{staticClass:"flex-1 pr-2"},[_c('h4',{staticClass:"card__subtitle"},[_vm._v(" Σ "+_vm._s(_vm.$t("labels.sum_produced"))+" ")]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"card__numeral"},[_vm._v(" "+_vm._s(_vm.seperateThousand( _vm.totalAmountData.total_amount_produced / 1000000 ))+" ")]),_vm._v(" MWh ")])]):_vm._e(),_c('div',{staticClass:"flex-1 pr-2"},[_c('h4',{staticClass:"card__subtitle"},[_vm._v(" Σ "+_vm._s(_vm.$t("labels.sum_matched"))+" ")]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"card__numeral"},[_vm._v(" "+_vm._s(_vm.roles.organizationType === _vm.OrganizationTypes.RETAILER ? _vm.seperateThousand( _vm.totalAmountData.total_amount_matched_production / 1000000 ) : _vm.seperateThousand( _vm.totalAmountData.total_amount_matched_consumption / 1000000 ))+" ")]),_vm._v(" MWh ")])])])])],1),_c('div',{staticClass:"col-8"},[_c('Card',{attrs:{"title":_vm.$t('labels.aggregated_matching_chart'),"title-type":"chart"},on:{"chart-change":function($event){return _vm.chartChange($event)}},scopedSlots:_vm._u([(_vm.roles.organizationType === _vm.OrganizationTypes.RETAILER)?{key:"actions",fn:function(){return [_c('button',{on:{"click":function($event){_vm.datalistVisible = !_vm.datalistVisible}}},[_c('SvgInjector',{staticClass:"text-primary",attrs:{"src":require('@/assets/img/icons/expand.svg'),"alt":""}})],1),_c('el-dialog',{attrs:{"title":_vm.$t('labels.hourly_aggregated_datalist'),"visible":_vm.datalistVisible},on:{"update:visible":function($event){_vm.datalistVisible=$event}}},[_c('AggregatedDatalist',{attrs:{"consumption-data":_vm.consumption.hourlyAggregatedData,"production-data":_vm.production.hourlyAggregatedData}})],1)]},proxy:true}:null],null,true)},[(
              [
                _vm.OrganizationTypes.RETAILER,
                _vm.OrganizationTypes.GRIDOPERATOR ].includes(_vm.roles.organizationType)
            )?_c('el-radio-group',{staticClass:"mb-4",model:{value:(_vm.chartType),callback:function ($$v) {_vm.chartType=$$v},expression:"chartType"}},[_c('el-radio-button',{attrs:{"label":"consumption"}},[_vm._v(" "+_vm._s(_vm.$t("common.consumption"))+" ")]),_c('el-radio-button',{attrs:{"label":"production"}},[_vm._v(" "+_vm._s(_vm.$t("common.production"))+" ")])],1):_vm._e(),(_vm.chartName === 'Aggregated Matching Chart')?_c('AggregatedChart',{key:("" + _vm.chartType),attrs:{"consumption-data":_vm.consumption.hourlyAggregatedData,"production-data":_vm.production.hourlyAggregatedData,"chart-type":_vm.roles.organizationType === _vm.OrganizationTypes.CONSUMER
                ? 'consumption'
                : _vm.roles.organizationType === _vm.OrganizationTypes.PRODUCER
                  ? 'production'
                  : _vm.chartType},on:{"state-change":function($event){return _vm.stateChange($event)}}}):_c('ApexChart',{key:("" + _vm.chartType + _vm.theme),attrs:{"consumption-data":_vm.consumption.allHourlyData,"production-data":_vm.production.allHourlyData,"chart-type":_vm.roles.organizationType === _vm.OrganizationTypes.CONSUMER
                ? 'consumption'
                : _vm.roles.organizationType === _vm.OrganizationTypes.PRODUCER
                  ? 'production'
                  : _vm.chartType},on:{"state-change":function($event){return _vm.stateChange($event)}}})],1)],1),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card__header"},[_c('h3',{staticClass:"card__title"},[_vm._v(" "+_vm._s(_vm.$t("labels.matched_renewable_share"))+" ")])]),(_vm.totalAmountData)?_c('div',{staticClass:"card__body"},[_c('div',{staticClass:"d-flex flex-column h-full"},[_c('PieChart',{attrs:{"props-data":_vm.totalAmountData,"show-table":""}}),_c('div',{staticClass:"py-4 h-full",style:({
                  overflowY: 'auto',
                })},[_c('div',[_c('ul',_vm._l((_vm.matchedProductionSites),function(site,i){return _c('li',{key:i},[_c('router-link',{staticClass:"d-flex align-items-center justify-content-between",attrs:{"to":{
                          name: 'ProductionSite',
                          params: { id: site.production_site },
                        }}},[_c('span',{staticClass:"flex-1 truncate"},[_vm._v(_vm._s(site.production_site__name))]),_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(( (site.total_matched / _vm.productionData.total_amount_matched) * 100 ).toFixed(2))+"%")])])],1)}),0)])])],1)]):_vm._e()])])]):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }