




























































































































































































































































































import {
  computed,
  defineComponent,
  provide,
  reactive,
  toRefs,
  watch
} from "@vue/composition-api";

import { getConsumptionDataList } from "@/api/energy-data/consumption_data";
import { getProductionDataList } from "@/api/energy-data/production_data";
import {
  IConsumptionData,
  IConsumptionDataHourlyAggregated,
  IConsumptionAllHourlyData
} from "@/lib/types/energy-data/consumption_data";
import {
  IProductionData,
  IProductionDataHourlyAggregated,
  IProductionAllHourlyData
} from "@/lib/types/energy-data/production_data";
import { dynamicSort, seperateThousand } from "@/utils";
import { rootInstance } from "@/helpers/composition";
import { OrganizationTypes } from "@/lib/types/base";

export default defineComponent({
  name: "Overview",
  components: {
    ConsumptionSitesCard: () =>
      import("@/components/cards/ConsumptionSitesCard.vue"),
    ProductionSitesCard: () =>
      import("@/components/cards/ProductionSitesCard.vue"),
    AggregatedChart: () => import("@/components/charts/AggregatedChart.vue"),
    AggregatedDatalist: () =>
      import("@/components/datalists/AggregatedDatalist.vue"),
    PieChart: () => import("@/components/charts/PieChart.vue"),
    ApexChart: () => import("@/components/charts/ApexChart.vue"),
  },
  computed: {
    roles() {
      return this.$store.getters["user/roles"];
    },
  },
  setup() {
    const { root } = rootInstance();
    const inf = Number.MAX_SAFE_INTEGER;

    const state = reactive({
      chartKey: 0,
      chartType: "consumption",
      theme: computed(() => root.$store.state.theme),
      datalistVisible: false,
      /*  */
      consumptionData: {} as IConsumptionData,
      consumptionDataLoaded: false,
      /*  */
      productionData: {} as IProductionData,
      productionDataLoaded: false,
      /*  */
      consumption: {
        sites: {
          consumed: [] as string[],
          matched: [] as string[],
        },
        hourlyAggregatedData: [] as IConsumptionDataHourlyAggregated[],
        allHourlyData: [] as IConsumptionAllHourlyData[],
      },
      production: {
        sites: {
          produced: [] as string[],
          matched: [] as string[],
        },
        hourlyAggregatedData: [] as IProductionDataHourlyAggregated[],
        allHourlyData: [] as IProductionAllHourlyData[],
      },
      chartName: "Stacked Area Chart",
    });

    const stateChange = (e: number) => {
      state.chartKey = e;
    };
    const chartChange = (e: any) => {
      state.chartName = e;
    };
    /**
     ** Set query string for URL
     **/
    watch(
      () => root.$store.state.period,
      () => {
        state.chartName = "Stacked Area Chart";
        root.$router
          .replace({
            query: Object.assign(
              {},
              {
                start: root.$store.state.period[0].split("T")[0],
                end: root.$store.state.period[1].split("T")[0],
              }
            ),
          })
          .catch(() => {});
      },
      { deep: true, immediate: true }
    );

    const matchedConsumptionSites = computed(() => {
      return state.consumptionData.sum_of_data_site_based.filter(
        site => site.total_matched > 0
      );
    });

    const matchedProductionSites = computed(() => {
      return state.productionData.sum_of_data_site_based.filter(
        site => site.total_matched > 0
      );
    });

    const consumptionDataListFn = () => {
      state.consumptionDataLoaded = false;

      getConsumptionDataList({
        consumptionStartAfter: root.$store.state.period[0],
        consumptionStartBefore: root.$store.state.period[1],
        limit: 1 || inf,
      })
        .then(res => {
          state.consumptionData = res;
          state.consumptionDataLoaded = true;

          state.consumption.hourlyAggregatedData =
            res.hourly_aggregated_data.sort(dynamicSort("hour"));
          state.consumption.allHourlyData =
            res.hourly_aggregated_data_for_each_day.sort(dynamicSort("date"));
        })
        .catch(err => console.error(JSON.stringify(err.errors.detail)))
        .finally(() => (state.consumptionDataLoaded = true));
    };

    const productionDataListFn = () => {
      state.productionDataLoaded = false;

      getProductionDataList({
        productionStartAfter: root.$store.state.period[0],
        productionStartBefore: root.$store.state.period[1],
        limit: 1 || inf,
      })
        .then(res => {
          state.productionData = res;
          state.productionDataLoaded = true;

          state.production.hourlyAggregatedData =
            res.hourly_aggregated_data.sort(dynamicSort("hour"));
          state.production.allHourlyData =
            res.hourly_aggregated_data_for_each_day.sort(dynamicSort("date"));
        })
        .catch(err => console.error(JSON.stringify(err.errors.detail)))
        .finally(() => (state.productionDataLoaded = true));
    };

    consumptionDataListFn();
    productionDataListFn();

    const totalAmountData = computed(() => {
      return {
        total_amount_produced: state.productionData.total_amount_produced,
        total_amount_consumed: state.consumptionData.total_amount_consumed,
        total_amount_matched_production:
          state.productionData.total_amount_matched,
        /* eslint-disable */
        total_amount_matched_consumption:
          state.consumptionData.total_amount_matched_solar! +
          state.consumptionData.total_amount_matched_wind! +
          state.consumptionData.total_amount_matched_hydro! +
          state.consumptionData.total_amount_matched_marine! +
          state.consumptionData.total_amount_matched_thermal! +
          state.consumptionData.total_amount_matched_solid! +
          state.consumptionData.total_amount_matched_liquid! +
          state.consumptionData.total_amount_matched_gaseous!,
        /* eslint-enable */
        total_amount_matched_solar:
          state.consumptionData.total_amount_matched_solar,
        total_amount_matched_wind:
          state.consumptionData.total_amount_matched_wind,
        total_amount_matched_hydro:
          state.consumptionData.total_amount_matched_hydro,
        total_amount_matched_marine:
          state.consumptionData.total_amount_matched_marine,
        total_amount_matched_thermal:
          state.consumptionData.total_amount_matched_thermal,
        total_amount_matched_solid:
          state.consumptionData.total_amount_matched_solid,
        total_amount_matched_liquid:
          state.consumptionData.total_amount_matched_liquid,
        total_amount_matched_gaseous:
          state.consumptionData.total_amount_matched_gaseous,
        total_production_sites_matched:
          state.consumptionData.total_production_sites_matched,
        total_consumption_sites_matched:
          state.productionData.total_consumption_sites_matched,
      };
    });

    watch(
      () => [root.$store.state.period],
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          consumptionDataListFn();
          productionDataListFn();
        }
      }
    );

    provide(
      "consumptionData",
      computed(() => state.consumptionData)
    );
    provide("consumption", state.consumption);
    provide(
      "productionData",
      computed(() => state.productionData)
    );
    provide("production", state.production);

    return {
      ...toRefs(state),
      matchedConsumptionSites,
      matchedProductionSites,
      seperateThousand,
      totalAmountData,
      stateChange,
      chartChange,
      OrganizationTypes,
    };
  },
});
